@font-face {
  font-family: 'BabasNeueBold';
  src: url('fonts/BebasNeue\ Bold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url('fonts/OpenSans-Regular.ttf');
  font-display: swap;
}
:root {
  --dominos_blue: #0078ac;
  --dominos_red: #fff;
  --dominos_white: #fff;
  --dominos_yellow: #ebbc00;
  --dominos-orange: #d16632;
}
body {
  background-color: #fafafa;
}
.svg-ic {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -400px;
}
.message-box {
  height: 200px;
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  color: var(--dominos_red);
  font-family: 'OpenSans-Regular';
  font-weight: 300;
}
.message-box h1 {
  font-size: 60px;
  line-height: 0px;
  font-family: 'BabasNeueBold';

  /* margin-bottom: 40px; */
}
.buttons-con .action-link-wrap {
  margin-top: 40px;
}
.buttons-con .action-link-wrap a {
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
}
.buttons-con .action-link-wrap a:hover {
  /* background: #5a5c6c; */
  color: #fff;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: 0.2s;
}
#Polygon-3 {
  animation-delay: 0.4s;
}
#Polygon-4 {
  animation-delay: 0.6s;
}
#Polygon-5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}
@media (max-width: 450px) {
  .svg-ic {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }
  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}

.slide:hover,
.slide:focus {
  box-shadow: inset 12em 0 0 0 var(--hover);
  transition: 2s;
  color: #fff;
  border-color: transparent !important;
  padding: 10px;
  border-radius: 20px;
  width: 335px;
}
.slide {
  --color: var(--dominos_blue);
  --hover: var(--dominos_blue);
  padding: 10px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  cursor: pointer;
  /* margin-top: 100px; */
}
.footer:hover {
  color: #00d421;
  transition: 1s;
  animation-delay: 0.2s;
  font-weight: 500px;
}
.footer {
  color: white;
  text-decoration: none;
  font-weight: 500px;
}
